import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const Performance = lazy(() => import('./screens/performance'));

const activeEvent = start => {
  const formatter = new Intl.DateTimeFormat('en-US', { timeZone: 'Asia/Yerevan' });
  const now_in_yerevan = new Date(formatter.format(new Date()));
  const start_in_yerevan = new Date(formatter.format(new Date(start)));
  return start_in_yerevan.getTime() > now_in_yerevan.getTime()
}

const LittleRedRidingHood = lazy(() => import('./screens/performance/little_red_riding_hood'));
const Ratatuy = lazy(() => import('./screens/performance/ratatuy'));
const TheThreeLittlePigs = lazy(() => import('./screens/performance/the_three_little_pigs'));
const ThreeCarrots = lazy(() => import('./screens/performance/three_carrots'));
const PenguinGio = lazy(() => import('./screens/performance/penguin_gio'));
const SnowmanOliver = lazy(() => import('./screens/performance/snowman_oliver'));

const Events = lazy(() => import('./screens/event'));
const ED_2024_12_14 = lazy(() => import('./screens/event/ED_2024_12_14'));
const ED_2024_12_15 = lazy(() => import('./screens/event/ED_2024_12_15'));
const ED_2024_12_17 = lazy(() => import('./screens/event/ED_2024_12_17'));
const ED_2024_12_18 = lazy(() => import('./screens/event/ED_2024_12_18'));
const ED_2024_12_19 = lazy(() => import('./screens/event/ED_2024_12_19'));
const ED_2024_12_20 = lazy(() => import('./screens/event/ED_2024_12_20'));
const ED_2024_12_21 = lazy(() => import('./screens/event/ED_2024_12_21'));
const ED_2024_12_22 = lazy(() => import('./screens/event/ED_2024_12_22'));
const ED_2024_12_24 = lazy(() => import('./screens/event/ED_2024_12_24'));
const ED_2024_12_25 = lazy(() => import('./screens/event/ED_2024_12_25'));
const ED_2024_12_26 = lazy(() => import('./screens/event/ED_2024_12_26'));
const ED_2024_12_27 = lazy(() => import('./screens/event/ED_2024_12_27'));
const ED_2024_12_28 = lazy(() => import('./screens/event/ED_2024_12_28'));
const ED_2024_12_29 = lazy(() => import('./screens/event/ED_2024_12_29'));
const ED_2025_01_04 = lazy(() => import('./screens/event/ED_2025_01_04'));
const ED_2025_01_05 = lazy(() => import('./screens/event/ED_2025_01_05'));
const ED_2025_01_10 = lazy(() => import('./screens/event/ED_2025_01_10'));
const ED_2025_01_11 = lazy(() => import('./screens/event/ED_2025_01_11'));
const ED_2025_01_12 = lazy(() => import('./screens/event/ED_2025_01_12'));
const ED_2025_01_13 = lazy(() => import('./screens/event/ED_2025_01_13'));
const E_2024_12_14_11_00 = lazy(() => import('./screens/event/E_2024_12_14_11_00'));
const E_2024_12_14_13_00 = lazy(() => import('./screens/event/E_2024_12_14_13_00'));
const E_2024_12_14_15_00 = lazy(() => import('./screens/event/E_2024_12_14_15_00'));
const E_2024_12_14_17_00 = lazy(() => import('./screens/event/E_2024_12_14_17_00'));
const E_2024_12_15_11_00 = lazy(() => import('./screens/event/E_2024_12_15_11_00'));
const E_2024_12_15_13_00 = lazy(() => import('./screens/event/E_2024_12_15_13_00'));
const E_2024_12_15_15_00 = lazy(() => import('./screens/event/E_2024_12_15_15_00'));
const E_2024_12_15_17_00 = lazy(() => import('./screens/event/E_2024_12_15_17_00'));
const E_2024_12_17_12_00 = lazy(() => import('./screens/event/E_2024_12_17_12_00'));
const E_2024_12_17_14_00 = lazy(() => import('./screens/event/E_2024_12_17_14_00'));
const E_2024_12_17_16_00 = lazy(() => import('./screens/event/E_2024_12_17_16_00'));
const E_2024_12_18_12_00 = lazy(() => import('./screens/event/E_2024_12_18_12_00'));
const E_2024_12_18_14_00 = lazy(() => import('./screens/event/E_2024_12_18_14_00'));
const E_2024_12_18_16_00 = lazy(() => import('./screens/event/E_2024_12_18_16_00'));
const E_2024_12_19_12_00 = lazy(() => import('./screens/event/E_2024_12_19_12_00'));
const E_2024_12_19_14_00 = lazy(() => import('./screens/event/E_2024_12_19_14_00'));
const E_2024_12_19_16_00 = lazy(() => import('./screens/event/E_2024_12_19_16_00'));
const E_2024_12_20_12_00 = lazy(() => import('./screens/event/E_2024_12_20_12_00'));
const E_2024_12_20_14_00 = lazy(() => import('./screens/event/E_2024_12_20_14_00'));
const E_2024_12_20_16_00 = lazy(() => import('./screens/event/E_2024_12_20_16_00'));
const E_2024_12_21_11_00 = lazy(() => import('./screens/event/E_2024_12_21_11_00'));
const E_2024_12_21_13_00 = lazy(() => import('./screens/event/E_2024_12_21_13_00'));
const E_2024_12_21_15_00 = lazy(() => import('./screens/event/E_2024_12_21_15_00'));
const E_2024_12_21_17_00 = lazy(() => import('./screens/event/E_2024_12_21_17_00'));
const E_2024_12_22_11_00 = lazy(() => import('./screens/event/E_2024_12_22_11_00'));
const E_2024_12_22_13_00 = lazy(() => import('./screens/event/E_2024_12_22_13_00'));
const E_2024_12_22_15_00 = lazy(() => import('./screens/event/E_2024_12_22_15_00'));
const E_2024_12_22_17_00 = lazy(() => import('./screens/event/E_2024_12_22_17_00'));
const E_2024_12_24_12_00 = lazy(() => import('./screens/event/E_2024_12_24_12_00'));
const E_2024_12_24_14_00 = lazy(() => import('./screens/event/E_2024_12_24_14_00'));
const E_2024_12_24_16_00 = lazy(() => import('./screens/event/E_2024_12_24_16_00'));
const E_2024_12_25_14_00 = lazy(() => import('./screens/event/E_2024_12_25_14_00'));
const E_2024_12_25_16_00 = lazy(() => import('./screens/event/E_2024_12_25_16_00'));
const E_2024_12_25_18_00 = lazy(() => import('./screens/event/E_2024_12_25_18_00'));
const E_2024_12_26_12_00 = lazy(() => import('./screens/event/E_2024_12_26_12_00'));
const E_2024_12_26_14_00 = lazy(() => import('./screens/event/E_2024_12_26_14_00'));
const E_2024_12_26_16_00 = lazy(() => import('./screens/event/E_2024_12_26_16_00'));
const E_2024_12_27_12_00 = lazy(() => import('./screens/event/E_2024_12_27_12_00'));
const E_2024_12_27_14_00 = lazy(() => import('./screens/event/E_2024_12_27_14_00'));
const E_2024_12_27_16_00 = lazy(() => import('./screens/event/E_2024_12_27_16_00'));
const E_2024_12_28_11_00 = lazy(() => import('./screens/event/E_2024_12_28_11_00'));
const E_2024_12_28_13_00 = lazy(() => import('./screens/event/E_2024_12_28_13_00'));
const E_2024_12_28_15_00 = lazy(() => import('./screens/event/E_2024_12_28_15_00'));
const E_2024_12_28_17_00 = lazy(() => import('./screens/event/E_2024_12_28_17_00'));
const E_2024_12_29_11_00 = lazy(() => import('./screens/event/E_2024_12_29_11_00'));
const E_2024_12_29_13_00 = lazy(() => import('./screens/event/E_2024_12_29_13_00'));
const E_2024_12_29_15_00 = lazy(() => import('./screens/event/E_2024_12_29_15_00'));
const E_2024_12_29_17_00 = lazy(() => import('./screens/event/E_2024_12_29_17_00'));
const E_2025_01_04_12_00 = lazy(() => import('./screens/event/E_2025_01_04_12_00'));
const E_2025_01_04_15_00 = lazy(() => import('./screens/event/E_2025_01_04_15_00'));
const E_2025_01_05_12_00 = lazy(() => import('./screens/event/E_2025_01_05_12_00'));
const E_2025_01_05_15_00 = lazy(() => import('./screens/event/E_2025_01_05_15_00'));
const E_2025_01_10_12_00 = lazy(() => import('./screens/event/E_2025_01_10_12_00'));
const E_2025_01_10_15_00 = lazy(() => import('./screens/event/E_2025_01_10_15_00'));
const E_2025_01_11_12_00 = lazy(() => import('./screens/event/E_2025_01_11_12_00'));
const E_2025_01_11_15_00 = lazy(() => import('./screens/event/E_2025_01_11_15_00'));
const E_2025_01_12_12_00 = lazy(() => import('./screens/event/E_2025_01_12_12_00'));
const E_2025_01_12_15_00 = lazy(() => import('./screens/event/E_2025_01_12_15_00'));
const E_2025_01_13_12_00 = lazy(() => import('./screens/event/E_2025_01_13_12_00'));
const E_2025_01_13_15_00 = lazy(() => import('./screens/event/E_2025_01_13_15_00'));
// Event Cashier
const CE_2024_12_14_11_00 = lazy(() => import('./screens/event/CE_2024_12_14_11_00'));
const CE_2024_12_14_13_00 = lazy(() => import('./screens/event/CE_2024_12_14_13_00'));
const CE_2024_12_14_15_00 = lazy(() => import('./screens/event/CE_2024_12_14_15_00'));
const CE_2024_12_14_17_00 = lazy(() => import('./screens/event/CE_2024_12_14_17_00'));
const CE_2024_12_15_11_00 = lazy(() => import('./screens/event/CE_2024_12_15_11_00'));
const CE_2024_12_15_13_00 = lazy(() => import('./screens/event/CE_2024_12_15_13_00'));
const CE_2024_12_15_15_00 = lazy(() => import('./screens/event/CE_2024_12_15_15_00'));
const CE_2024_12_15_17_00 = lazy(() => import('./screens/event/CE_2024_12_15_17_00'));
const CE_2024_12_17_12_00 = lazy(() => import('./screens/event/CE_2024_12_17_12_00'));
const CE_2024_12_17_14_00 = lazy(() => import('./screens/event/CE_2024_12_17_14_00'));
const CE_2024_12_17_16_00 = lazy(() => import('./screens/event/CE_2024_12_17_16_00'));
const CE_2024_12_18_12_00 = lazy(() => import('./screens/event/CE_2024_12_18_12_00'));
const CE_2024_12_18_14_00 = lazy(() => import('./screens/event/CE_2024_12_18_14_00'));
const CE_2024_12_18_16_00 = lazy(() => import('./screens/event/CE_2024_12_18_16_00'));
const CE_2024_12_19_12_00 = lazy(() => import('./screens/event/CE_2024_12_19_12_00'));
const CE_2024_12_19_14_00 = lazy(() => import('./screens/event/CE_2024_12_19_14_00'));
const CE_2024_12_19_16_00 = lazy(() => import('./screens/event/CE_2024_12_19_16_00'));
const CE_2024_12_20_12_00 = lazy(() => import('./screens/event/CE_2024_12_20_12_00'));
const CE_2024_12_20_14_00 = lazy(() => import('./screens/event/CE_2024_12_20_14_00'));
const CE_2024_12_20_16_00 = lazy(() => import('./screens/event/CE_2024_12_20_16_00'));
const CE_2024_12_21_11_00 = lazy(() => import('./screens/event/CE_2024_12_21_11_00'));
const CE_2024_12_21_13_00 = lazy(() => import('./screens/event/CE_2024_12_21_13_00'));
const CE_2024_12_21_15_00 = lazy(() => import('./screens/event/CE_2024_12_21_15_00'));
const CE_2024_12_21_17_00 = lazy(() => import('./screens/event/CE_2024_12_21_17_00'));
const CE_2024_12_22_11_00 = lazy(() => import('./screens/event/CE_2024_12_22_11_00'));
const CE_2024_12_22_13_00 = lazy(() => import('./screens/event/CE_2024_12_22_13_00'));
const CE_2024_12_22_15_00 = lazy(() => import('./screens/event/CE_2024_12_22_15_00'));
const CE_2024_12_22_17_00 = lazy(() => import('./screens/event/CE_2024_12_22_17_00'));
const CE_2024_12_24_12_00 = lazy(() => import('./screens/event/CE_2024_12_24_12_00'));
const CE_2024_12_24_14_00 = lazy(() => import('./screens/event/CE_2024_12_24_14_00'));
const CE_2024_12_24_16_00 = lazy(() => import('./screens/event/CE_2024_12_24_16_00'));
const CE_2024_12_25_14_00 = lazy(() => import('./screens/event/CE_2024_12_25_14_00'));
const CE_2024_12_25_16_00 = lazy(() => import('./screens/event/CE_2024_12_25_16_00'));
const CE_2024_12_25_18_00 = lazy(() => import('./screens/event/CE_2024_12_25_18_00'));
const CE_2024_12_26_12_00 = lazy(() => import('./screens/event/CE_2024_12_26_12_00'));
const CE_2024_12_26_14_00 = lazy(() => import('./screens/event/CE_2024_12_26_14_00'));
const CE_2024_12_26_16_00 = lazy(() => import('./screens/event/CE_2024_12_26_16_00'));
const CE_2024_12_27_12_00 = lazy(() => import('./screens/event/CE_2024_12_27_12_00'));
const CE_2024_12_27_14_00 = lazy(() => import('./screens/event/CE_2024_12_27_14_00'));
const CE_2024_12_27_16_00 = lazy(() => import('./screens/event/CE_2024_12_27_16_00'));
const CE_2024_12_28_11_00 = lazy(() => import('./screens/event/CE_2024_12_28_11_00'));
const CE_2024_12_28_13_00 = lazy(() => import('./screens/event/CE_2024_12_28_13_00'));
const CE_2024_12_28_15_00 = lazy(() => import('./screens/event/CE_2024_12_28_15_00'));
const CE_2024_12_28_17_00 = lazy(() => import('./screens/event/CE_2024_12_28_17_00'));
const CE_2024_12_29_11_00 = lazy(() => import('./screens/event/CE_2024_12_29_11_00'));
const CE_2024_12_29_13_00 = lazy(() => import('./screens/event/CE_2024_12_29_13_00'));
const CE_2024_12_29_15_00 = lazy(() => import('./screens/event/CE_2024_12_29_15_00'));
const CE_2024_12_29_17_00 = lazy(() => import('./screens/event/CE_2024_12_29_17_00'));
const CE_2025_01_04_12_00 = lazy(() => import('./screens/event/CE_2025_01_04_12_00'));
const CE_2025_01_04_15_00 = lazy(() => import('./screens/event/CE_2025_01_04_15_00'));
const CE_2025_01_05_12_00 = lazy(() => import('./screens/event/CE_2025_01_05_12_00'));
const CE_2025_01_05_15_00 = lazy(() => import('./screens/event/CE_2025_01_05_15_00'));
const CE_2025_01_10_12_00 = lazy(() => import('./screens/event/CE_2025_01_10_12_00'));
const CE_2025_01_10_15_00 = lazy(() => import('./screens/event/CE_2025_01_10_15_00'));
const CE_2025_01_11_12_00 = lazy(() => import('./screens/event/CE_2025_01_11_12_00'));
const CE_2025_01_11_15_00 = lazy(() => import('./screens/event/CE_2025_01_11_15_00'));
const CE_2025_01_12_12_00 = lazy(() => import('./screens/event/CE_2025_01_12_12_00'));
const CE_2025_01_12_15_00 = lazy(() => import('./screens/event/CE_2025_01_12_15_00'));
const CE_2025_01_13_12_00 = lazy(() => import('./screens/event/CE_2025_01_13_12_00'));
const CE_2025_01_13_15_00 = lazy(() => import('./screens/event/CE_2025_01_13_15_00'));

export function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<Events />}>
            { true && <Route exact path='/event/ED_2024_12_14' element={<ED_2024_12_14 />} /> }
            { true && <Route exact path='/event/ED_2024_12_15' element={<ED_2024_12_15 />} /> }
            { true && <Route exact path='/event/ED_2024_12_17' element={<ED_2024_12_17 />} /> }
            { true && <Route exact path='/event/ED_2024_12_18' element={<ED_2024_12_18 />} /> }
            { true && <Route exact path='/event/ED_2024_12_19' element={<ED_2024_12_19 />} /> }
            { true && <Route exact path='/event/ED_2024_12_20' element={<ED_2024_12_20 />} /> }
            { true && <Route exact path='/event/ED_2024_12_21' element={<ED_2024_12_21 />} /> }
            { true && <Route exact path='/event/ED_2024_12_22' element={<ED_2024_12_22 />} /> }
            { true && <Route exact path='/event/ED_2024_12_24' element={<ED_2024_12_24 />} /> }
            { true && <Route exact path='/event/ED_2024_12_25' element={<ED_2024_12_25 />} /> }
            { true && <Route exact path='/event/ED_2024_12_26' element={<ED_2024_12_26 />} /> }
            { true && <Route exact path='/event/ED_2024_12_27' element={<ED_2024_12_27 />} /> }
            { true && <Route exact path='/event/ED_2024_12_28' element={<ED_2024_12_28 />} /> }
            { true && <Route exact path='/event/ED_2024_12_29' element={<ED_2024_12_29 />} /> }
            { true && <Route exact path='/event/ED_2025_01_04' element={<ED_2025_01_04 />} /> }
            { true && <Route exact path='/event/ED_2025_01_05' element={<ED_2025_01_05 />} /> }
            { true && <Route exact path='/event/ED_2025_01_10' element={<ED_2025_01_10 />} /> }
            { true && <Route exact path='/event/ED_2025_01_11' element={<ED_2025_01_11 />} /> }
            { true && <Route exact path='/event/ED_2025_01_12' element={<ED_2025_01_12 />} /> }
            { true && <Route exact path='/event/ED_2025_01_13' element={<ED_2025_01_13 />} /> }
          </Route>
          <Route path='/event' element={<Events />}>
            { true && <Route exact path='/event/ED_2024_12_14' element={<ED_2024_12_14 />} /> }
            { true && <Route exact path='/event/ED_2024_12_15' element={<ED_2024_12_15 />} /> }
            { true && <Route exact path='/event/ED_2024_12_17' element={<ED_2024_12_17 />} /> }
            { true && <Route exact path='/event/ED_2024_12_18' element={<ED_2024_12_18 />} /> }
            { true && <Route exact path='/event/ED_2024_12_19' element={<ED_2024_12_19 />} /> }
            { true && <Route exact path='/event/ED_2024_12_20' element={<ED_2024_12_20 />} /> }
            { true && <Route exact path='/event/ED_2024_12_21' element={<ED_2024_12_21 />} /> }
            { true && <Route exact path='/event/ED_2024_12_22' element={<ED_2024_12_22 />} /> }
            { true && <Route exact path='/event/ED_2024_12_24' element={<ED_2024_12_24 />} /> }
            { true && <Route exact path='/event/ED_2024_12_25' element={<ED_2024_12_25 />} /> }
            { true && <Route exact path='/event/ED_2024_12_26' element={<ED_2024_12_26 />} /> }
            { true && <Route exact path='/event/ED_2024_12_27' element={<ED_2024_12_27 />} /> }
            { true && <Route exact path='/event/ED_2024_12_28' element={<ED_2024_12_28 />} /> }
            { true && <Route exact path='/event/ED_2024_12_29' element={<ED_2024_12_29 />} /> }
            { true && <Route exact path='/event/ED_2025_01_04' element={<ED_2025_01_04 />} /> }
            { true && <Route exact path='/event/ED_2025_01_05' element={<ED_2025_01_05 />} /> }
            { true && <Route exact path='/event/ED_2025_01_10' element={<ED_2025_01_10 />} /> }
            { true && <Route exact path='/event/ED_2025_01_11' element={<ED_2025_01_11 />} /> }
            { true && <Route exact path='/event/ED_2025_01_12' element={<ED_2025_01_12 />} /> }
            { true && <Route exact path='/event/ED_2025_01_13' element={<ED_2025_01_13 />} /> }
          </Route>
          <Route exact path='/event' element={<Events />} />
          { activeEvent('2024/12/14 11:00') && <Route exact path='/event/E_2024_12_14_11_00' element={<E_2024_12_14_11_00 />} /> }
          { activeEvent('2024/12/14 11:00') && <Route exact path='/cashier/E_2024_12_14_11_00' element={<CE_2024_12_14_11_00 />} /> }
          { activeEvent('2024/12/14 13:00') && <Route exact path='/event/E_2024_12_14_13_00' element={<E_2024_12_14_13_00 />} /> }
          { activeEvent('2024/12/14 13:00') && <Route exact path='/cashier/E_2024_12_14_13_00' element={<CE_2024_12_14_13_00 />} /> }
          { activeEvent('2024/12/14 15:00') && <Route exact path='/event/E_2024_12_14_15_00' element={<E_2024_12_14_15_00 />} /> }
          { activeEvent('2024/12/14 15:00') && <Route exact path='/cashier/E_2024_12_14_15_00' element={<CE_2024_12_14_15_00 />} /> }
          { activeEvent('2024/12/14 17:00') && <Route exact path='/event/E_2024_12_14_17_00' element={<E_2024_12_14_17_00 />} /> }
          { activeEvent('2024/12/14 17:00') && <Route exact path='/cashier/E_2024_12_14_17_00' element={<CE_2024_12_14_17_00 />} /> }
          { activeEvent('2024/12/15 11:00') && <Route exact path='/event/E_2024_12_15_11_00' element={<E_2024_12_15_11_00 />} /> }
          { activeEvent('2024/12/15 11:00') && <Route exact path='/cashier/E_2024_12_15_11_00' element={<CE_2024_12_15_11_00 />} /> }
          { activeEvent('2024/12/15 13:00') && <Route exact path='/event/E_2024_12_15_13_00' element={<E_2024_12_15_13_00 />} /> }
          { activeEvent('2024/12/15 13:00') && <Route exact path='/cashier/E_2024_12_15_13_00' element={<CE_2024_12_15_13_00 />} /> }
          { activeEvent('2024/12/15 15:00') && <Route exact path='/event/E_2024_12_15_15_00' element={<E_2024_12_15_15_00 />} /> }
          { activeEvent('2024/12/15 15:00') && <Route exact path='/cashier/E_2024_12_15_15_00' element={<CE_2024_12_15_15_00 />} /> }
          { activeEvent('2024/12/15 17:00') && <Route exact path='/event/E_2024_12_15_17_00' element={<E_2024_12_15_17_00 />} /> }
          { activeEvent('2024/12/15 17:00') && <Route exact path='/cashier/E_2024_12_15_17_00' element={<CE_2024_12_15_17_00 />} /> }
          { activeEvent('2024/12/17 12:00') && <Route exact path='/event/E_2024_12_17_12_00' element={<E_2024_12_17_12_00 />} /> }
          { activeEvent('2024/12/17 12:00') && <Route exact path='/cashier/E_2024_12_17_12_00' element={<CE_2024_12_17_12_00 />} /> }
          { activeEvent('2024/12/17 14:00') && <Route exact path='/event/E_2024_12_17_14_00' element={<E_2024_12_17_14_00 />} /> }
          { activeEvent('2024/12/17 14:00') && <Route exact path='/cashier/E_2024_12_17_14_00' element={<CE_2024_12_17_14_00 />} /> }
          { activeEvent('2024/12/17 16:00') && <Route exact path='/event/E_2024_12_17_16_00' element={<E_2024_12_17_16_00 />} /> }
          { activeEvent('2024/12/17 16:00') && <Route exact path='/cashier/E_2024_12_17_16_00' element={<CE_2024_12_17_16_00 />} /> }
          { activeEvent('2024/12/18 12:00') && <Route exact path='/event/E_2024_12_18_12_00' element={<E_2024_12_18_12_00 />} /> }
          { activeEvent('2024/12/18 12:00') && <Route exact path='/cashier/E_2024_12_18_12_00' element={<CE_2024_12_18_12_00 />} /> }
          { activeEvent('2024/12/18 14:00') && <Route exact path='/event/E_2024_12_18_14_00' element={<E_2024_12_18_14_00 />} /> }
          { activeEvent('2024/12/18 14:00') && <Route exact path='/cashier/E_2024_12_18_14_00' element={<CE_2024_12_18_14_00 />} /> }
          { activeEvent('2024/12/18 16:00') && <Route exact path='/event/E_2024_12_18_16_00' element={<E_2024_12_18_16_00 />} /> }
          { activeEvent('2024/12/18 16:00') && <Route exact path='/cashier/E_2024_12_18_16_00' element={<CE_2024_12_18_16_00 />} /> }
          { activeEvent('2024/12/19 12:00') && <Route exact path='/event/E_2024_12_19_12_00' element={<E_2024_12_19_12_00 />} /> }
          { activeEvent('2024/12/19 12:00') && <Route exact path='/cashier/E_2024_12_19_12_00' element={<CE_2024_12_19_12_00 />} /> }
          { activeEvent('2024/12/19 14:00') && <Route exact path='/event/E_2024_12_19_14_00' element={<E_2024_12_19_14_00 />} /> }
          { activeEvent('2024/12/19 14:00') && <Route exact path='/cashier/E_2024_12_19_14_00' element={<CE_2024_12_19_14_00 />} /> }
          { activeEvent('2024/12/19 16:00') && <Route exact path='/event/E_2024_12_19_16_00' element={<E_2024_12_19_16_00 />} /> }
          { activeEvent('2024/12/19 16:00') && <Route exact path='/cashier/E_2024_12_19_16_00' element={<CE_2024_12_19_16_00 />} /> }
          { activeEvent('2024/12/20 12:00') && <Route exact path='/event/E_2024_12_20_12_00' element={<E_2024_12_20_12_00 />} /> }
          { activeEvent('2024/12/20 12:00') && <Route exact path='/cashier/E_2024_12_20_12_00' element={<CE_2024_12_20_12_00 />} /> }
          { activeEvent('2024/12/20 14:00') && <Route exact path='/event/E_2024_12_20_14_00' element={<E_2024_12_20_14_00 />} /> }
          { activeEvent('2024/12/20 14:00') && <Route exact path='/cashier/E_2024_12_20_14_00' element={<CE_2024_12_20_14_00 />} /> }
          { activeEvent('2024/12/20 16:00') && <Route exact path='/event/E_2024_12_20_16_00' element={<E_2024_12_20_16_00 />} /> }
          { activeEvent('2024/12/20 16:00') && <Route exact path='/cashier/E_2024_12_20_16_00' element={<CE_2024_12_20_16_00 />} /> }
          { activeEvent('2024/12/21 11:00') && <Route exact path='/event/E_2024_12_21_11_00' element={<E_2024_12_21_11_00 />} /> }
          { activeEvent('2024/12/21 11:00') && <Route exact path='/cashier/E_2024_12_21_11_00' element={<CE_2024_12_21_11_00 />} /> }
          { activeEvent('2024/12/21 13:00') && <Route exact path='/event/E_2024_12_21_13_00' element={<E_2024_12_21_13_00 />} /> }
          { activeEvent('2024/12/21 13:00') && <Route exact path='/cashier/E_2024_12_21_13_00' element={<CE_2024_12_21_13_00 />} /> }
          { activeEvent('2024/12/21 15:00') && <Route exact path='/event/E_2024_12_21_15_00' element={<E_2024_12_21_15_00 />} /> }
          { activeEvent('2024/12/21 15:00') && <Route exact path='/cashier/E_2024_12_21_15_00' element={<CE_2024_12_21_15_00 />} /> }
          { activeEvent('2024/12/21 17:00') && <Route exact path='/event/E_2024_12_21_17_00' element={<E_2024_12_21_17_00 />} /> }
          { activeEvent('2024/12/21 17:00') && <Route exact path='/cashier/E_2024_12_21_17_00' element={<CE_2024_12_21_17_00 />} /> }
          { activeEvent('2024/12/22 11:00') && <Route exact path='/event/E_2024_12_22_11_00' element={<E_2024_12_22_11_00 />} /> }
          { activeEvent('2024/12/22 11:00') && <Route exact path='/cashier/E_2024_12_22_11_00' element={<CE_2024_12_22_11_00 />} /> }
          { activeEvent('2024/12/22 13:00') && <Route exact path='/event/E_2024_12_22_13_00' element={<E_2024_12_22_13_00 />} /> }
          { activeEvent('2024/12/22 13:00') && <Route exact path='/cashier/E_2024_12_22_13_00' element={<CE_2024_12_22_13_00 />} /> }
          { activeEvent('2024/12/22 15:00') && <Route exact path='/event/E_2024_12_22_15_00' element={<E_2024_12_22_15_00 />} /> }
          { activeEvent('2024/12/22 15:00') && <Route exact path='/cashier/E_2024_12_22_15_00' element={<CE_2024_12_22_15_00 />} /> }
          { activeEvent('2024/12/22 17:00') && <Route exact path='/event/E_2024_12_22_17_00' element={<E_2024_12_22_17_00 />} /> }
          { activeEvent('2024/12/22 17:00') && <Route exact path='/cashier/E_2024_12_22_17_00' element={<CE_2024_12_22_17_00 />} /> }
          { activeEvent('2024/12/24 12:00') && <Route exact path='/event/E_2024_12_24_12_00' element={<E_2024_12_24_12_00 />} /> }
          { activeEvent('2024/12/24 12:00') && <Route exact path='/cashier/E_2024_12_24_12_00' element={<CE_2024_12_24_12_00 />} /> }
          { activeEvent('2024/12/24 14:00') && <Route exact path='/event/E_2024_12_24_14_00' element={<E_2024_12_24_14_00 />} /> }
          { activeEvent('2024/12/24 14:00') && <Route exact path='/cashier/E_2024_12_24_14_00' element={<CE_2024_12_24_14_00 />} /> }
          { activeEvent('2024/12/24 16:00') && <Route exact path='/event/E_2024_12_24_16_00' element={<E_2024_12_24_16_00 />} /> }
          { activeEvent('2024/12/24 16:00') && <Route exact path='/cashier/E_2024_12_24_16_00' element={<CE_2024_12_24_16_00 />} /> }
          { activeEvent('2024/12/25 14:00') && <Route exact path='/event/E_2024_12_25_14_00' element={<E_2024_12_25_14_00 />} /> }
          { activeEvent('2024/12/25 14:00') && <Route exact path='/cashier/E_2024_12_25_14_00' element={<CE_2024_12_25_14_00 />} /> }
          { activeEvent('2024/12/25 16:00') && <Route exact path='/event/E_2024_12_25_16_00' element={<E_2024_12_25_16_00 />} /> }
          { activeEvent('2024/12/25 16:00') && <Route exact path='/cashier/E_2024_12_25_16_00' element={<CE_2024_12_25_16_00 />} /> }
          { activeEvent('2024/12/25 18:00') && <Route exact path='/event/E_2024_12_25_18_00' element={<E_2024_12_25_18_00 />} /> }
          { activeEvent('2024/12/25 18:00') && <Route exact path='/cashier/E_2024_12_25_18_00' element={<CE_2024_12_25_18_00 />} /> }
          { activeEvent('2024/12/26 12:00') && <Route exact path='/event/E_2024_12_26_12_00' element={<E_2024_12_26_12_00 />} /> }
          { activeEvent('2024/12/26 12:00') && <Route exact path='/cashier/E_2024_12_26_12_00' element={<CE_2024_12_26_12_00 />} /> }
          { activeEvent('2024/12/26 14:00') && <Route exact path='/event/E_2024_12_26_14_00' element={<E_2024_12_26_14_00 />} /> }
          { activeEvent('2024/12/26 14:00') && <Route exact path='/cashier/E_2024_12_26_14_00' element={<CE_2024_12_26_14_00 />} /> }
          { activeEvent('2024/12/26 16:00') && <Route exact path='/event/E_2024_12_26_16_00' element={<E_2024_12_26_16_00 />} /> }
          { activeEvent('2024/12/26 16:00') && <Route exact path='/cashier/E_2024_12_26_16_00' element={<CE_2024_12_26_16_00 />} /> }
          { activeEvent('2024/12/27 12:00') && <Route exact path='/event/E_2024_12_27_12_00' element={<E_2024_12_27_12_00 />} /> }
          { activeEvent('2024/12/27 12:00') && <Route exact path='/cashier/E_2024_12_27_12_00' element={<CE_2024_12_27_12_00 />} /> }
          { activeEvent('2024/12/27 14:00') && <Route exact path='/event/E_2024_12_27_14_00' element={<E_2024_12_27_14_00 />} /> }
          { activeEvent('2024/12/27 14:00') && <Route exact path='/cashier/E_2024_12_27_14_00' element={<CE_2024_12_27_14_00 />} /> }
          { activeEvent('2024/12/27 16:00') && <Route exact path='/event/E_2024_12_27_16_00' element={<E_2024_12_27_16_00 />} /> }
          { activeEvent('2024/12/27 16:00') && <Route exact path='/cashier/E_2024_12_27_16_00' element={<CE_2024_12_27_16_00 />} /> }
          { activeEvent('2024/12/28 11:00') && <Route exact path='/event/E_2024_12_28_11_00' element={<E_2024_12_28_11_00 />} /> }
          { activeEvent('2024/12/28 11:00') && <Route exact path='/cashier/E_2024_12_28_11_00' element={<CE_2024_12_28_11_00 />} /> }
          { activeEvent('2024/12/28 13:00') && <Route exact path='/event/E_2024_12_28_13_00' element={<E_2024_12_28_13_00 />} /> }
          { activeEvent('2024/12/28 13:00') && <Route exact path='/cashier/E_2024_12_28_13_00' element={<CE_2024_12_28_13_00 />} /> }
          { activeEvent('2024/12/28 15:00') && <Route exact path='/event/E_2024_12_28_15_00' element={<E_2024_12_28_15_00 />} /> }
          { activeEvent('2024/12/28 15:00') && <Route exact path='/cashier/E_2024_12_28_15_00' element={<CE_2024_12_28_15_00 />} /> }
          { activeEvent('2024/12/28 17:00') && <Route exact path='/event/E_2024_12_28_17_00' element={<E_2024_12_28_17_00 />} /> }
          { activeEvent('2024/12/28 17:00') && <Route exact path='/cashier/E_2024_12_28_17_00' element={<CE_2024_12_28_17_00 />} /> }
          { activeEvent('2024/12/29 11:00') && <Route exact path='/event/E_2024_12_29_11_00' element={<E_2024_12_29_11_00 />} /> }
          { activeEvent('2024/12/29 11:00') && <Route exact path='/cashier/E_2024_12_29_11_00' element={<CE_2024_12_29_11_00 />} /> }
          { activeEvent('2024/12/29 13:00') && <Route exact path='/event/E_2024_12_29_13_00' element={<E_2024_12_29_13_00 />} /> }
          { activeEvent('2024/12/29 13:00') && <Route exact path='/cashier/E_2024_12_29_13_00' element={<CE_2024_12_29_13_00 />} /> }
          { activeEvent('2024/12/29 15:00') && <Route exact path='/event/E_2024_12_29_15_00' element={<E_2024_12_29_15_00 />} /> }
          { activeEvent('2024/12/29 15:00') && <Route exact path='/cashier/E_2024_12_29_15_00' element={<CE_2024_12_29_15_00 />} /> }
          { activeEvent('2024/12/29 17:00') && <Route exact path='/event/E_2024_12_29_17_00' element={<E_2024_12_29_17_00 />} /> }
          { activeEvent('2024/12/29 17:00') && <Route exact path='/cashier/E_2024_12_29_17_00' element={<CE_2024_12_29_17_00 />} /> }
          { activeEvent('2025/01/04 12:00') && <Route exact path='/event/E_2025_01_04_12_00' element={<E_2025_01_04_12_00 />} /> }
          { activeEvent('2025/01/04 12:00') && <Route exact path='/cashier/E_2025_01_04_12_00' element={<CE_2025_01_04_12_00 />} /> }
          { activeEvent('2025/01/04 15:00') && <Route exact path='/event/E_2025_01_04_15_00' element={<E_2025_01_04_15_00 />} /> }
          { activeEvent('2025/01/04 15:00') && <Route exact path='/cashier/E_2025_01_04_15_00' element={<CE_2025_01_04_15_00 />} /> }
          { activeEvent('2025/01/05 12:00') && <Route exact path='/event/E_2025_01_05_12_00' element={<E_2025_01_05_12_00 />} /> }
          { activeEvent('2025/01/05 12:00') && <Route exact path='/cashier/E_2025_01_05_12_00' element={<CE_2025_01_05_12_00 />} /> }
          { activeEvent('2025/01/05 15:00') && <Route exact path='/event/E_2025_01_05_15_00' element={<E_2025_01_05_15_00 />} /> }
          { activeEvent('2025/01/05 15:00') && <Route exact path='/cashier/E_2025_01_05_15_00' element={<CE_2025_01_05_15_00 />} /> }
          { activeEvent('2025/01/10 12:00') && <Route exact path='/event/E_2025_01_10_12_00' element={<E_2025_01_10_12_00 />} /> }
          { activeEvent('2025/01/10 12:00') && <Route exact path='/cashier/E_2025_01_10_12_00' element={<CE_2025_01_10_12_00 />} /> }
          { activeEvent('2025/01/10 15:00') && <Route exact path='/event/E_2025_01_10_15_00' element={<E_2025_01_10_15_00 />} /> }
          { activeEvent('2025/01/10 15:00') && <Route exact path='/cashier/E_2025_01_10_15_00' element={<CE_2025_01_10_15_00 />} /> }
          { activeEvent('2025/01/11 12:00') && <Route exact path='/event/E_2025_01_11_12_00' element={<E_2025_01_11_12_00 />} /> }
          { activeEvent('2025/01/11 12:00') && <Route exact path='/cashier/E_2025_01_11_12_00' element={<CE_2025_01_11_12_00 />} /> }
          { activeEvent('2025/01/11 15:00') && <Route exact path='/event/E_2025_01_11_15_00' element={<E_2025_01_11_15_00 />} /> }
          { activeEvent('2025/01/11 15:00') && <Route exact path='/cashier/E_2025_01_11_15_00' element={<CE_2025_01_11_15_00 />} /> }
          { activeEvent('2025/01/12 12:00') && <Route exact path='/event/E_2025_01_12_12_00' element={<E_2025_01_12_12_00 />} /> }
          { activeEvent('2025/01/12 12:00') && <Route exact path='/cashier/E_2025_01_12_12_00' element={<CE_2025_01_12_12_00 />} /> }
          { activeEvent('2025/01/12 15:00') && <Route exact path='/event/E_2025_01_12_15_00' element={<E_2025_01_12_15_00 />} /> }
          { activeEvent('2025/01/12 15:00') && <Route exact path='/cashier/E_2025_01_12_15_00' element={<CE_2025_01_12_15_00 />} /> }
          { activeEvent('2025/01/13 12:00') && <Route exact path='/event/E_2025_01_13_12_00' element={<E_2025_01_13_12_00 />} /> }
          { activeEvent('2025/01/13 12:00') && <Route exact path='/cashier/E_2025_01_13_12_00' element={<CE_2025_01_13_12_00 />} /> }
          { activeEvent('2025/01/13 15:00') && <Route exact path='/event/E_2025_01_13_15_00' element={<E_2025_01_13_15_00 />} /> }
          { activeEvent('2025/01/13 15:00') && <Route exact path='/cashier/E_2025_01_13_15_00' element={<CE_2025_01_13_15_00 />} /> }
          
          <Route exact path='/performance' element={<Performance />} />
          <Route exact path='/performance/little_red_riding_hood' element={<LittleRedRidingHood />} />
          <Route exact path='/performance/ratatuy' element={<Ratatuy />} />
          <Route exact path='/performance/the_three_little_pigs' element={<TheThreeLittlePigs />} />
          <Route exact path='/performance/three_carrots' element={<ThreeCarrots />} />
          <Route exact path='/performance/penguin_gio' element={<PenguinGio />} />
          <Route exact path='/performance/snowman_oliver' element={<SnowmanOliver />} />
        </Routes>
      </Suspense>
    </Router>
  )
}
